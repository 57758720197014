
  import Vue from "vue";
  import PwaDepositBanner from '@/components/deposit/PwaDepositBanner.vue';
  import {ROUTES} from "@/router/routes";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import { COMPETITION_PROPOSAL_STATUS_ID, COMPETITION_TYPE_IDS } from '@/utils/competition';
  import { EVENTS } from '@/utils/events';

  const COMPETITIONS_TABS = {
    MATCHES: 0,
    MULTIPLAYER: 1,
    RANKED: 2,
  };

  export default Vue.extend({
    name: "Competitions",
    mixins: [pageMixin],
    components: {
      PwaCompetitionCard: () => import("@/components/competitions/PwaCompetitionCard.vue"),
      PwaCompetitionRankedCard: () => import("@/components/competitions/PwaCompetitionRankedCard.vue"),
      PwaMultiplayerCard: () => import("@/components/competitions/PwaMultiplayerCard.vue"),
      PwaCompetitionsProposals: () => import("@/components/competitions/PwaCompetitionsProposals.vue"),
      PwaDepositBanner,
      PwaCrossSellingBanner: () => import('@/components/PwaCrossSellingBanner.vue'),
      PwaDownloadAppBanner: () => import('@/components/PwaDownloadAppBanner.vue'),
    },
    data() {
      return {
        activeCompetitions: null,
        wagers: [],
        rankeds: [],
        multiplayers: [],
        competitionTypeIndexSelected: null,
        hasHistoricalCompetitions: false,
        competitionsPerBlock: 4,
        activeCompetitionShowBlock: 0,
        breadcrumb: [{labelId: BreadcrumbItems.competitions.labelId}] as BreadcrumbItemType[],
        competitionsProposals: {
          sent: [],
          received: [],
        },
        competitionFreeInscriptions: [],
        bannerData: {} as any,
      }
    },
    mounted(): void {
      if(this.isTablet){
        this.competitionsPerBlock = 3;
      }
      this.getUserActiveCompetitions();
      this.$root.$on(EVENTS.RELOAD_COMPETITIONS_PAGE, () => this.getUserActiveCompetitions());
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.RELOAD_COMPETITIONS_PAGE);
    },
    computed: {
      activeCompetitionsSelected(): any[] {
        let competitions = [];
        if (this.competitionTypeIndexSelected === COMPETITIONS_TABS.MATCHES) {
          competitions = this.wagers || [];
        } else if (this.competitionTypeIndexSelected === COMPETITIONS_TABS.RANKED) {
          competitions = this.rankeds || [];
        } else if (this.competitionTypeIndexSelected === COMPETITIONS_TABS.MULTIPLAYER) {
          competitions = this.multiplayers || [];
        }

        return competitions;
      },
      activeCompetitionsToShow(): any[] {
        return this.activeCompetitionsSelected.slice(0, (this.activeCompetitionShowBlock * this.competitionsPerBlock) + this.competitionsPerBlock);
      },
      hasCompetitions(): boolean {
        return this.activeCompetitions != null && this.activeCompetitions.length > 0;
      },
      hasChallenges(): boolean {
        return this.competitionsProposals.sent.length > 0 ||  this.competitionsProposals.received.length > 0;
      },
      hasActiveChallenges(): boolean {
        return (
          (this.competitionsProposals.sent.length > 0 && this.competitionsProposals.sent.find((competitionProposal: any) => COMPETITION_PROPOSAL_STATUS_ID.PENDING === competitionProposal.status_id)) ||
          (this.competitionsProposals.received.length > 0 && this.competitionsProposals.received.find((competitionProposal: any) => COMPETITION_PROPOSAL_STATUS_ID.PENDING === competitionProposal.status_id))
        );
      },
    },
    methods: {
      async getUserActiveCompetitions() {
        try {
          this.showLoader(true);
          const {data} = await this.$http.page.getCompetitionsPage();
          this.activeCompetitions = data.data.active_competitions || [];
          this.activeCompetitions.forEach((competition: any) => {
            if(competition.type_id == COMPETITION_TYPE_IDS.WAGER){
              this.wagers.push(competition);
            }else if(competition.type_id == COMPETITION_TYPE_IDS.RANKED){
              this.rankeds.push(competition);
            }else if(competition.type_id == COMPETITION_TYPE_IDS.MULTIPLAYER){
              this.multiplayers.push(competition);
            }
          });
          this.hasHistoricalCompetitions = data.data.has_historical_competitions;
          this.competitionsProposals = data.data.competitions_proposals || {sent: [], received: []};
          this.competitionFreeInscriptions = data.data.competitions_free_inscriptions || [];
          this.bannerData = data.data.banner_data || {};
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onShowMoreClick() {
        this.activeCompetitionShowBlock = this.activeCompetitionShowBlock + 1;
      },
      onViewHistoryClick() {
        this.$router.push({name: ROUTES.competitionsHistory.name, params: {lang: this.routeLang}});
      },
      findMatch() {
        this.$router.push({name: ROUTES.lobby.name, params: {lang: this.routeLang}});
      }
    },
  });
